import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { localData } from '@/stores/local-data'
import { userStore } from '@/stores/user-store'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class LoginViewModel {
  @observable emailInput = ''
  @observable passwordInput = ''
  @observable loading = false

  constructor() {
    //
  }

  @asyncAction *login() {
    try {
      this.loading = true
      const requestBody = {
        identifier: this.emailInput,
        password: this.passwordInput
      }
      yield userStore.signIn(requestBody)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }
}
