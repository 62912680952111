

















































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { LoginViewModel } from '@/modules/admin/viewmodels/login-viewmodel'

@Observer
@Component
export default class Login extends Vue {
  @Provide() vm = new LoginViewModel()
}
